/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import config from '../config'
import { useCurrentWidth } from '../hooks'

const GCP_DEVELOPMENT = process.env.GATSBY_DEVELOPMENT_BUILD === 'true'

/**
 * Constant values.
 */
const COOKIE_NAME = 'consent'
const CURRENT_COOKIE_VERSION = 2

/**
 * Component itself.
 */
export default function cookieconsent() {
    // Notification state
    const [opened, setOpened] = useState(false)
    const [updatedOnce, setUpdatedOnce] = useState(false)

    // Cookie state (only accepts latest version as valid).
    const initial = Cookies.get(COOKIE_NAME) ? JSON.parse(Cookies.get(COOKIE_NAME)) : null
    const [cookie, setCookieState] = useState(initial?.version >= CURRENT_COOKIE_VERSION ? initial : null)

    // Preferences state (take cookie value as initial state where possible).
    const [userPreferences, setUserPreferences] = useState(cookie?.destinations ?? null)
    const [isCustomizing, setIsCustomizing] = useState(false)

    // Loading state
    const [segmentLoading, setSegmentLoading] = useState(true)

    // Media query helper
    const width = useCurrentWidth()

    // Take according value based on .env file.
    const keySwitcher = GCP_DEVELOPMENT ? process.env.GATSBY_TEST_SEGMENT : process.env.GATSBY_PROD_SEGMENT

    // Prevent Server Side Rendering from processing this value.
    const isNotSSR = () => typeof window !== 'undefined'

    /**
     * Set up other ranges to accept.
     * Ranges are defined by ourselves;
     * No official definitions of these.
     */
    const Average = ['Livechat', 'A/B Testing']
    const Good = [...Average, 'Tag Managers', 'Analytics']
    const Great = [...Good, 'Advertising']

    useEffect(() => {
        // Track attribution, regardless.
        loadAttributionTracking()

        /**
         * Initial load for Segment.
         * Any preferences will only be
         * utilized in next calls, as Segment
         * needs to restart in order to take
         * the users preferences.
         */
        initializeSegmentScript()

        // Start the rest of the functions.
        setSegmentLoading(false)
    }, [])

    useEffect(() => {
        /**
         * User has no preferences yet;
         * load destinations first to base
         * user descisions on, despite preference.
         */
        if (isNotSSR && userPreferences === null) {
            fetchDestinationForWriteKey(keySwitcher)
        }

        // User has previously denied the cookie.
        if (isNotSSR && (!cookie || (cookie && cookie.value === false))) {
            setOpened(true)
        }
    }, [segmentLoading])

    /**
     * On scroll, accept cookies, set to 'updated'
     * to prevent re-opening of notification,
     * and close the notification.
     */
    useEffect(() => {
        const alreadyAcceptedCookies = cookie !== null
        if (!alreadyAcceptedCookies && !updatedOnce) {
            setToRangeAndAccept(Great)
            setUpdatedOnce(true)
            setOpened(false)
        }
    }, [])

    /**
     * Set cookie with value.
     *
     * @see https://github.com/js-cookie/js-cookie
     * with a touch of https://github.com/segmentio/consent-manager/blob/master/src/consent-manager-builder/preferences.ts
     * @param {boolean} value
     */
    const setCookie = (value, state) => {
        const body = {
            value,
            version: CURRENT_COOKIE_VERSION,
            destinations: {
                ...Object.entries(state || userPreferences || {})
                    // Reduce to just 'true' or 'false', prevent polluting cookie.
                    .reduce((acc, [key, { enabled }]) => ({ ...acc, [key]: enabled }), {}),
                Webhooks: true,
            },
        }

        setCookieState({ body })
        Cookies.set(COOKIE_NAME, JSON.stringify(body), {
            expires: 1000,
            domain: config.cookieDomain,
        })
    }

    /**
     * Loads Segment script.
     * Used to first load Segment,
     * regardless of user preferences.
     * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/ajs-classic/#load-options
     */
    const initializeSegmentScript = (preferences) => {
        const everything = { All: true }

        /**
         * Always set 'Webhooks' (Category 'Raw Data')
         * to true, as this is required for Segment
         * to properly do it's work (i.e. 'track').
         */
        if (window.analytics && window.analytics.invoked && !window.analytics.initialized) {
            window.analytics.load(keySwitcher, {
                integrations: {
                    ...(preferences || cookie?.destinations || everything),
                    Webhooks: true,
                },
            })
        }
    }

    /**
     * Reload the Segment script
     * whenever an event happens.
     */
    const updateSegment = () => {
        initializeSegmentScript({
            All: Boolean(Object.values(userPreferences || {}).some(({ enabled }) => enabled !== false)),
            // Create objects with { 'name': boolean } types. Assure boolean values.
            ...Object.values(userPreferences || {}).reduce((acc, { creationName, enabled }) => ({ ...acc, [creationName]: Boolean(enabled) }), {}),
        })
    }

    /**
     * Loads Sourcebuster.
     * @see https://github.com/alexfedoseev/sourcebuster-js
     */
    const loadAttributionTracking = () => {
        if (isNotSSR && window.sbjs) {
            window.sbjs.init({
                // Custom expiration period of sourcebuster cookies (in months).
                lifetime: 1,
                host: 'tellow.nl',
                callback: (sbData) => {
                    console.debug('Attribution', sbData)
                },
            })
        }
    }

    const fetchDestinationForWriteKey = async (writeKey) => {
        const res = await fetch(`https://cdn.segment.com/v1/projects/${writeKey}/integrations`)
        if (!res.ok) return

        /**
         * Get destinations and set
         * them all to true by default.
         */
        const destinations = await res.json()
        setUserPreferences(destinations.reduce((acc, curr) => ({ ...acc, [curr.creationName]: { ...curr, enabled: true } }), {}))
    }

    const setToRangeAndAccept = (range) => {
        const body = Object.entries(userPreferences || {}).reduce(
            // Return current and set enabled state
            (acc, [key, value]) => ({ ...acc, [key]: { ...value, enabled: range?.includes(value.category) } }),
            {}
        )

        setUserPreferences(body)
        handleCookieDecision(true, body)
    }

    /**
     * Process given answer to the cookie question.
     * @param {boolean} value to set as accepted answer.
     * @param {object} state (optionally) pass on state to prevent race condition.
     */
    const handleCookieDecision = (value, state) => {
        setCookie(value, state)
        updateSegment()

        setOpened(false)
    }

    return null
}
