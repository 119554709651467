import React from 'react'
import Button from './button'

const RegisterButton = (props) => {
    const href = () => {
        let base = 'https://app.tellow.nl/registreer'

        if (props.campaign || props.email || props.lang || props.tier) base += '?'
        if (props.campaign) base += `origin=${props.campaign}`
        if ((props.campaign && props.email) || (props.campaign && props.lang)) base += '&'
        if (props.email) base += `_email=${props.email}`
        if ((props.email && props.lang) || (props.campaign && props.lang)) base += '&'
        if (props.lang) base += `lang=${props.lang}`
        if ((props.lang && props.tier) || (props.email && props.tier) || (props.campaign && props.tier)) base += '&'
        if (props.tier) base += `tier=${props.tier}`

        return base
    }

    return (
        <Button
            {...props}
            href={!props.disabled && (props.url ? props.url : href())}
        >
            {props.children}
        </Button>
    )
}

export default RegisterButton
