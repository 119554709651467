/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import tw from 'twin.macro'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import Login from '../Login/login'
import RegisterButton from '../Buttons/registerButton'
import Mobile from '../menu/mobile/menu'
import Dropdown from '../menu/desktop/dropdown'
import { Bar, LogoLink, Wrap, StyledFunctionDropdown, StyledLink, DropdownOrchestrator, StyledSpan, DropdownArrow } from './styles'
import { useTranslate } from '../../../hooks'
import UseToggle from '../../../hooks/useToggle'
import DropDownIcon from '../Icons/dropDownIcon'
import TellowLogoAnimated from '../../../images/logos/tellow/LogoAnimated'

const style = {
    bar: tw`w-screen mx-auto px-4 md:px-6 lg:px-8 `,
    slide: tw`absolute top-0 -mt-4 h-0.5 bg-white`,
    button: tw`text-tellow-purple! text-center`,
}

export const Slide = ({ to, language, children, usePrimaryColor, primaryColor }) => {
    const [hover, setHover] = UseToggle()

    return (
        <StyledSpan className="menu-slider" usePrimaryColor={usePrimaryColor} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} primaryColor={primaryColor}>
            <motion.span animate={{ width: hover ? '100%' : '0%' }} transition={{ ease: 'easeInOut', duration: 0.25, type: 'just' }} />
            <StyledLink activeClassName="active" language={language} to={to}>
                {children}
            </StyledLink>
        </StyledSpan>
    )
}

const MenuItemVariants = {
    hidden: {
        y: -20,
        opacity: 0,
    },
    visible: {
        x: 0,
        y: 0,
        opacity: 1,
    },
}

const transition = {
    duration: 0.15,
    type: 'spring',
    mass: 0.25,
}

// When navbar is purple, the tellow logo is white. On hover, the navbar turns white and the tellow logo turns purple
// When Navbar is white, the tellow logo is purple. On hover, the navbar stays white and the tellow stays purple.

const LoginButton = styled(Login)`
    color: ${({ isPurple, isOpen, theme }) => (isPurple && !isOpen ? theme.color.tellowWhite : theme.color.tellowPurple)};
`

function NavBar({ parentBackground, registrationButtonCampaignId, registrationButtonTier }) {
    const { t, i18n } = useTranslate('components', 'menu')
    const [isOpen, setIsOpen] = UseToggle()
    // background color of the Nav bar is read
    const isPurple = parentBackground === 'rgb(89, 80, 236)'

    return (
        <Wrap className="no-print" usePrimaryColor={isOpen}>
            <Bar css={style.bar} usePrimaryColor={isOpen}>
                <div className="menu__mobile">
                    <Mobile registrationButtonCampaignId={registrationButtonCampaignId} registrationButtonTier={registrationButtonTier} />
                </div>

                <div className="row__container">
                    <div className="branding">
                        <LogoLink to="/" language={i18n} rel="home" title="Tellow: boekhouding voor freelancers">
                            <TellowLogoAnimated
                                theme={isPurple && !isOpen ? 'light' : 'dark'}
                            />
                        </LogoLink>
                    </div>
                    <div className="menu__desktop">
                        <DropdownOrchestrator
                            onClick={() => {
                                setIsOpen()
                            }}
                        >
                            <Slide language={i18n} usePrimaryColor={isOpen}>
                                {t.features}
                                <DropdownArrow style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0)' }}>
                                    <DropDownIcon />
                                </DropdownArrow>
                            </Slide>

                            {isOpen && (
                                <StyledFunctionDropdown variants={MenuItemVariants} transition={transition} initial="hidden" animate="visible">
                                    <Dropdown />
                                </StyledFunctionDropdown>
                            )}
                        </DropdownOrchestrator>
                        <Slide to="/functies/zakelijke-rekening-openen" language={i18n} usePrimaryColor={isOpen}>
                            {t.banking}
                        </Slide>
                        <Slide to="/prijs" language={i18n} usePrimaryColor={isOpen}>
                            {t.price}
                        </Slide>
                        <Slide to="/plan/compleet" language={i18n} usePrimaryColor={isOpen}>
                            {t.bookkeeper}
                        </Slide>
                        <Slide to="/reviews" language={i18n} usePrimaryColor={isOpen}>
                            {t.reviews}
                        </Slide>
                    </div>
                </div>

                <div className="cta">
                    <LoginButton isPurple={isPurple} isOpen={isOpen} context="navigation" />
                    <RegisterButton {...(isPurple && !isOpen ? { primary: true } : {})} context="navigation" campaign={registrationButtonCampaignId} tier={registrationButtonTier}>
                        {t.signup}
                        <span className="arrow">{'->'}</span>
                    </RegisterButton>
                </div>
            </Bar>
        </Wrap>
    )
}

export default NavBar
