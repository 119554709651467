import React from 'react'
import styled from 'styled-components'
import Lottie from 'lottie-react'
import mobileDarkLogoAnimation from './Tellow_mobile_Purple_longer.json'
import mobileLightLogoAnimation from './Tellow_mobile_White_longer.json'
import darkLogoAnimation from './Tellow_website_Purple_longer.json'
import lightLogoAnimation from './Tellow_website_White_longer.json'
import { useDeviceDetect } from '../../../hooks'

const StyledLogoContainer = styled.div`
    width: auto;
    height: 24px;
    display: flex;
`

const getAnimationData = (isMobile, theme) => {
    if (isMobile) {
        return theme === 'light' ? mobileLightLogoAnimation : mobileDarkLogoAnimation
    }
    return theme === 'light' ? lightLogoAnimation : darkLogoAnimation
}

export const TellowLogoAnimated = ({ theme }) => {
    const { isMobile } = useDeviceDetect()

    const animationData = getAnimationData(isMobile, theme)

    return (
        <StyledLogoContainer isMobile={isMobile}>
            <Lottie animationData={animationData} loop useSubFrames={false} />
        </StyledLogoContainer>
    )
}

export default TellowLogoAnimated
