import React from 'react'

// const Content = styled(Container)`
//     form {
//         padding-top: 1rem;
//         text-align: center;
//         width: auto;

//         p {
//             color: ${(props) => props.theme.color.tellowGray500};
//             font-size: 0.75rem;
//             padding-top: 1rem;
//         }
//     }

//     label {
//         background: ${(props) => props.theme.color.tellowWhite};
//         border-radius: 2rem;
//         color: ${(props) => props.theme.color.tellowBlue};
//         display: block;
//         text-align: center;
//         padding: 1rem;
//         margin-left: auto;
//         margin-right: auto;
//         width: 100%;

//         input {
//             margin-right: 1rem;
//         }
//     }

//     label:hover {
//         animation: none;
//     }

//     input[type='email'] {
//         margin-right: 0.5rem;
//     }

//     input[type='email'],
//     button {
//         border: 0;
//         display: inline-block;
//         border-radius: 2rem;
//         margin: 0.5rem;
//         padding: 0.5rem 1rem 0.5rem 1rem;
//         width: 45%;

//         ::placeholder {
//             color: ${(props) => props.theme.color.tellowGreen};
//         }

//         &:focus {
//             outline: none;
//         }

//         @media screen and (max-width: 768px) {
//             display: block;
//             margin-left: auto;
//             margin-right: auto;
//             width: 90%;
//         }
//     }

//     button {
//         background: ${(props) => props.theme.color.tellowGreen};
//         box-shadow: ${(props) => props.theme.subtleShadow};
//         color: ${(props) => props.theme.color.tellowWhite};
//         cursor: pointer;

//         :disabled {
//             background: ${(props) => props.theme.color.tellowGray500};
//             cursor: not-allowed;
//         }
//     }

//     input[type='email'] {
//         background: ${(props) => props.theme.color.tellowWhite};
//         border: 3px solid ${(props) => props.theme.color.tellowGreen};
//         box-shadow: ${(props) => props.theme.subtleShadow};
//     }

//     input[type='checkbox'] {
//         -webkit-appearance: none;
//         -moz-appearance: none;
//         appearance: none;
//         display: inline-block;
//         position: relative;
//         background-color: ${(props) => props.theme.color.tellowGray100};
//         color: ${(props) => props.theme.color.tellowWhite};
//         top: 7px;
//         height: 25px;
//         width: 25px;
//         border: 2px solid ${(props) => props.theme.color.tellowGray200};
//         border-radius: 5px;
//         cursor: pointer;
//         margin-right: 15px;
//         outline: none;

//         &:checked:before {
//             position: absolute;
//             font: 13px/1 'Open Sans', sans-serif;
//             left: 5px;
//             top: 4px;
//             content: '✔';
//         }

//         &:hover {
//             background-color: ${(props) => props.theme.color.tellowGreen};
//             opacity: 0.5;
//         }

//         &:checked {
//             background-color: ${(props) => props.theme.color.tellowGreen};
//             border: 2px solid ${(props) => props.theme.color.tellowGray200};
//         }
//     }
// `

/**
 * TODO: Refactor.
 */

// class CTA extends React.Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             email: '',
//             GDPR: false,
//             interacting: false,
//             isSending: false,
//             label: this.props.labelName,
//         }
//     }

//     handleInputChange = (event) => {
//         const { value, name } = event.target
//         this.setState({
//             [name]: value,
//         })
//     }

//     handleSubmit = (event) => {
//         event.preventDefault()

//         this.setState({ isSending: true })

//         fetch(`https://europe-west1-${IS_PRODUCTION ? 'tellow-nl-production' : 'hellotellow-test'}.cloudfunctions.net/intercom`, {
//             body: JSON.stringify({
//                 email: this.state.email,
//                 label: this.state.label,
//                 production: IS_PRODUCTION,
//             }),
//             headers: {
//                 Accept: 'application/json',
//                 'Content-Type': 'application/json',
//             },
//             method: 'POST',
//             redirect: 'follow',
//         })
//             // .then(response => response.json())
//             .then((response) => {
//                 this.setState({ isSending: false })

//                 if (response.status === 200) {
//                     window.location.replace(`../bedankt/`)
//                 }
//             })
//             .catch((e) => console.log('error', e))
//     }

//     handleGDPR() {
//         this.setState((state) => ({ GDPR: !state.GDPR }))
//     }

//     consentCheck() {
//         if (this.state.email.length && !this.state.GDPR) {
//             this.state.interacting = true
//         }
//     }

//     render() {
//         return (
//             <Content>
//                 <form onSubmit={this.handleSubmit}>
//                     <label htmlFor="GDPR" onClick={() => this.handleGDPR()} style={!this.state.GDPR && this.state.interacting ? { color: '#F0554D' } : { color: '#011532' }}>
//                         <input type="checkbox" name="GDPR" checked={this.state.GDPR} onChange={() => this.handleGDPR()} onClick={() => this.handleGDPR()} />
//                         Stuur mij het <b>gratis</b> starterspakket.
//                     </label>
//                     <input type="email" name="email" placeholder="info@email.nl" value={this.state.email} onInput={() => this.consentCheck()} onChange={this.handleInputChange} />
//                     <button type="submit" disabled={!this.state.GDPR}>
//                         {this.state.isSending ? 'Bezig...' : 'Verzend'}
//                     </button>
//                     <p>
//                         Wij sturen je af en toe meer startersadvies en updates van Tellow. Door het gratis starterspakket op te vragen ga je hiermee akkoord. Je kan je altijd weer
//                         afmelden.
//                     </p>
//                 </form>
//             </Content>
//         )
//     }
// }

// export default CTA

const CTA = () => <></>

export default CTA
