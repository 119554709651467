// /* eslint-disable no-nested-ternary */
// /* eslint-disable jsx-a11y/label-has-associated-control */
// /* eslint-disable no-unused-vars */
import React from 'react'

// import { useForm } from 'react-hook-form'
// import { yupResolver } from '@hookform/resolvers/yup'
// import * as yup from 'yup'
// import { setLocale } from 'yup'
// import { AnimatePresence } from 'framer-motion'
// import tw from 'twin.macro'
// import {
//     Center,
//     StyledInput,
//     Select,
//     Label,
//     Error,
//     Success,
//     SpanTwo,
//     Conditions,
//     Button,
//     Margin,
//     Card,
//     SignupContainer,
//     ScreenreaderLabel,
//     Heading,
//     Grid,
//     Form,
//     SignupWrapper,
//     Container,
//     Main,
//     Wrapper,
//     Explanation,
//     Checkbox,
// } from './register.styled'
// import theme from '../../theme'

// /**
//  * Check if we're on 'development' / firebase preview.
//  * Regular NODE_ENV does not work, since deploying
//  * to firebase is not 'development' NODE_ENV
//  * (but a production build).
//  *
//  * GATSBY_DEVELOPMENT_BUILD is set during build.
//  */
// const DEVELOPMENT = process.env.NODE_ENV === 'development' || process.env.GATSBY_DEVELOPMENT_BUILD === 'true'

// /**
//  * Dropdown options.
//  */
// const LegalFormType = {
//     TYPE_BV: 'BV',
//     TYPE_SOLE_PROPRIETORSHIP: 'SOLE_PROP',
// }

// /**
//  * Max lengths for form inputs.
//  */
// const maxInputLengths = {
//     userFirstName: 50,
//     userLastName: 50,
//     userPhoneNumber: 32,
//     companyName: 50,
// }

// /**
//  * Set custom i18n error messages.
//  */
// setLocale({
//     mixed: {
//         required: 'Dit veld is vereist.',
//     },
//     string: {
//         min: 'Dit is te kort.',
//         max: 'Teveel karakters in gebruik.',
//         email: 'Dit moet een e-mailadres zijn.',
//         matches: 'Ongeldige karakters in gebruik.',
//     },
// })

// /**
//  * Validation schema for form.
//  */
// const schema = yup.object().shape({
//     userEmail: yup.string().email().required(),
//     userPhoneNumber: yup
//         .string()
//         .min(10)
//         .max(maxInputLengths.userPhoneNumber)
//         .matches(/^(?=.*[0-9])[- +()0-9]+$/)
//         .required(),
//     userFirstName: yup
//         .string()
//         .required()
//         .max(maxInputLengths.userFirstName)
//         .matches(/^[a-z\x7f-\xff '-]+$/i),
//     userLastName: yup
//         .string()
//         .required()
//         .max(maxInputLengths.userLastName)
//         .matches(/^[a-z\x7f-\xff '-]+$/i),
//     origin: yup.string().max(maxInputLengths.origin),
//     companyLegalForm: yup.string().required(),
//     companyName: yup.string().max(maxInputLengths.companyName),
//     companyHasVat: yup.boolean(),
// })

// /**
//  * Loading spinner.
//  */
// const Spinner = () => (
//     <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
//         <defs>
//             <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
//                 <stop stopColor={theme.color.tellowPurple} stopOpacity="0" offset="0%" />
//                 <stop stopColor={theme.color.tellowPurple} stopOpacity=".631" offset="63.146%" />
//                 <stop stopColor={theme.color.tellowPurple} offset="100%" />
//             </linearGradient>
//         </defs>
//         <g fill="none" fillRule="evenodd">
//             <g transform="translate(1 1)">
//                 <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" strokeWidth="2">
//                     <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite" />
//                 </path>
//                 <circle fill={theme.color.tellowPurple} cx="36" cy="18" r="1">
//                     <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite" />
//                 </circle>
//             </g>
//         </g>
//     </svg>
// )

// /**
//  * Pass motion
//  * props.
//  *
//  * Key required to
//  * track component.
//  */
// const randomKey = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
// const Motion = ({ children }) =>
//     Children.map(children, (child) =>
//         cloneElement(child, { key: randomKey, initial: { opacity: 0 }, transition: { duration: 0.15, type: 'easeInOut' }, animate: { opacity: 1 }, exit: { opacity: 0 } })
//     )

// /**
//  * Check icon.
//  */
// const Check = () => (
//     <svg width="24" height="24" style={{ marginRight: '1rem' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//         <path strokeLinecap="round" strokeLinejoin="round" fill="none" strokeWidth={2} d="M5 13l4 4L19 7" />
//     </svg>
// )

// /**
//  * Signup subcomponent;
//  * to contain all its own logic.
//  */
// const Signup = ({ variant }) => {
//     const [mailSent, setMailSent] = useState(false)
//     const [created, setCreated] = useState(false)
//     const [busy, setBusy] = useState(false)
//     const [done, setDone] = useState(false)

//     /**
//      * Form logic.
//      */
//     const [customError, setCustomError] = useState(null)
//     const { register, handleSubmit, setError, errors } = useForm({
//         resolver: yupResolver(schema),
//         defaultValues: {
//             companyLegalForm: LegalFormType.TYPE_SOLE_PROPRIETORSHIP,
//             userSubscribeToNewsletter: false,
//         },
//     })

//     /**
//      * Track first step on init.
//      * useEffect without dependencies (empty array)
//      * acts as the init lifecycle hook
//      * (and runs only once).
//      */
//     useEffect(() => {
//         track(1)
//     }, [])

//     /**
//      * Map over the backend response
//      * when all goes south.
//      *
//      * @param {array} messages list of fields that are incorrecto.
//      */
//     const mapBackendErrors = (messages) => {
//         Object.keys(messages).forEach((fld) => {
//             if (!fld) return

//             /**
//              * Use setError hook provided
//              * by react-form-hook.
//              */
//             setError(fld, {
//                 type: 'manual',
//                 message: 'Dit lijkt niet te kloppen.',
//             })
//         })
//     }

//     /**
//      * Filter out unwanted responses
//      * from POST fetch.
//      */
//     const handleResponse = (response) =>
//         response.json().then((json) => {
//             if (response.ok) {
//                 return json
//             }

//             const error = {
//                 ...json,
//                 status: response.status,
//             }

//             return Promise.reject(error)
//         })

//     /**
//      * Submit handler. Ran through validation first
//      * through the handleSubmit method found
//      * on the useForm hook above.
//      *
//      * @param {object} data from 'react form hook'.
//      */
//     const onSubmit = (data) => {
//         setCustomError(null)
//         setBusy(true)

//         /**
//          * Append data with required fields
//          * that we do not let the user fill
//          * in themselves.
//          */
//         const body = {
//             ...data,
//             userAcceptedTerms: true,
//             client_id: process.env.NODE_ENV === 'development' ? '1_wfj2y7d2phwsgw0w4w04ko0kw0000g4s48ow88ggg480wgwow' : process.env.GATSBY_CLIENT_ID,
//         }

//         /**
//          * POST to backend.
//          */
//         const env = DEVELOPMENT ? '.test' : ''
//         fetch(`https://api${env}.tellow.nl/v2/registration/register`, {
//             method: 'POST',
//             body: JSON.stringify(body),
//             headers: {
//                 'Content-Type': 'application/json',
//                 'X-Referer': `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
//                 'X-App': `WEB:${process.env.NODE_ENV === 'development' ? '3.2.1' : process.env.GATSBY_VERSION}`,
//             },
//         })
//             /**
//              * If we throw a '400' expection,
//              * we need to manually catch this.
//              * Fetch does not see '400' as
//              * an error code.
//              */
//             .then(handleResponse)
//             .then(() => {
//                 track(3)
//                 setDone(true)
//                 setBusy(false)

//                 /**
//                  * Give a timeout to
//                  * 'account created!'
//                  * notification.
//                  */
//                 setCreated(true)
//                 setTimeout(() => {
//                     setCreated(false)
//                 }, 5000)
//             })
//             .then(() => {
//                 /**
//                  * Send user OTP
//                  * for login.
//                  */
//                 fetch(`https://api${env}.tellow.nl/v2/login/magic-token`, {
//                     method: 'POST',
//                     body: JSON.stringify({ email: data.userEmail }),
//                     headers: {
//                         'Content-Type': 'application/json',
//                         'X-Referer': `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
//                         'X-App': `WEB:${process.env.NODE_ENV === 'development' ? '3.2.1' : process.env.GATSBY_VERSION}`,
//                     },
//                 })
//                     .then((rsp) => setMailSent(true))
//                     .catch((e) => setCustomError('Er ging iets mis met versturen.'))
//             })
//             .catch((err) => {
//                 /**
//                  * Not busy anymore,
//                  * are we?
//                  */
//                 setBusy(false)
//                 console.error('Error:', err)

//                 /**
//                  * Set the corresponding error message.
//                  * This is needed for i18n.
//                  */
//                 if (err.error_code && err.error_code === 7002) {
//                     setCustomError('Emailadres wordt al gebruikt.')
//                 } else if (err.error_messages) {
//                     mapBackendErrors(err.error_messages)
//                     setCustomError('Formulier foutief ingevuld.')
//                 } else {
//                     setCustomError('Er ging iets verkeerd.')
//                 }
//             })
//     }

//     /**
//      * Representational part.
//      * Make it look pretty.
//      */
//     return (
//         <SignupWrapper>
//             <AnimatePresence>
//                 <SignupContainer variant={variant || 'white'}>
//                     <Card>
//                         <Margin>
//                             {!done && !busy && (
//                                 <Form onSubmit={handleSubmit(onSubmit)}>
//                                     <SpanTwo>
//                                         <div>
//                                             <ScreenreaderLabel htmlFor="userFirstName">Voornaam</ScreenreaderLabel>
//                                             <StyledInput ref={register} type="text" name="userFirstName" id="userFirstName" placeholder="Pieter" />
//                                             <Error hidden={!errors.userFirstName?.message}>{errors.userFirstName?.message}</Error>
//                                         </div>

//                                         <div>
//                                             <ScreenreaderLabel htmlFor="userLastName">Achternaam</ScreenreaderLabel>
//                                             <StyledInput ref={register} type="text" name="userLastName" id="userLastName" placeholder="de Jong" />
//                                             <Error hidden={!errors.userLastName?.message}>{errors.userLastName?.message}</Error>
//                                         </div>
//                                     </SpanTwo>

//                                     <SpanTwo>
//                                         <div>
//                                             <ScreenreaderLabel htmlFor="companyName">Bedrijfsnaam</ScreenreaderLabel>
//                                             <StyledInput ref={register} type="text" name="companyName" id="companyName" placeholder="Pieters Bakkerij" />
//                                             <Error hidden={!errors.companyName?.message}>{errors.companyName?.message}</Error>
//                                         </div>

//                                         <div>
//                                             <ScreenreaderLabel htmlFor="companyLegalForm">Bedrijfsvorm</ScreenreaderLabel>
//                                             <Select ref={register} id="companyLegalForm" name="companyLegalForm">
//                                                 <option value={LegalFormType.TYPE_BV}>BV</option>
//                                                 <option value={LegalFormType.TYPE_SOLE_PROPRIETORSHIP}>Eenmanszaak</option>
//                                             </Select>
//                                             <Error hidden={!errors.companyLegalForm?.message}>{errors.companyLegalForm?.message}</Error>
//                                         </div>
//                                     </SpanTwo>
//                                     <SpanTwo>
//                                         <div>
//                                             <ScreenreaderLabel htmlFor="userEmail">E-mail</ScreenreaderLabel>
//                                             <StyledInput ref={register} type="text" name="userEmail" id="userEmail" placeholder="pieter@bakkerij.nl" />
//                                             <Error hidden={!errors.userEmail?.message}>{errors.userEmail?.message}</Error>
//                                         </div>

//                                         <div>
//                                             <ScreenreaderLabel htmlFor="userPhoneNumber">Telefoonnummer</ScreenreaderLabel>
//                                             <StyledInput ref={register} type="text" name="userPhoneNumber" id="userPhoneNumber" placeholder="0612344321" />
//                                             <Error hidden={!errors.userPhoneNumber?.message}>{errors.userPhoneNumber?.message}</Error>
//                                         </div>
//                                     </SpanTwo>

//                                     {customError && <Error>{customError}</Error>}

//                                     <div>
//                                         <Button disabled={busy} type="submit">
//                                             Maak Tellow account
//                                         </Button>
//                                     </div>

//                                     <SpanTwo>
//                                         <Center>
//                                             <Label>
//                                                 <Checkbox ref={register} id="userSubscribeToNewsletter" name="userSubscribeToNewsletter" type="checkbox" />
//                                                 Ik wil de nieuwsbrief.
//                                             </Label>
//                                             <Error hidden={!errors.userSubscribeToNewsletter?.message}>{errors.userSubscribeToNewsletter?.message}</Error>
//                                         </Center>
//                                     </SpanTwo>
//                                 </Form>
//                             )}
//                             {busy && (
//                                 <Center tw="-ml-4">
//                                     <Spinner />
//                                     <div tw="flex flex-col ml-6">
//                                         <h3 tw="m-0 text-sm font-semibold tracking-tight antialiased text-tellow-purple">Je account is in de maak!</h3>
//                                         <p tw="m-0 text-xs font-medium tracking-tight antialiased text-gray-500">Eventjes geduld</p>
//                                     </div>
//                                 </Center>
//                             )}
//                             {/* General information. */}
//                             {done && (
//                                 <>
//                                     <Motion>
//                                         <Explanation>
//                                             <h3>Veiligheid</h3>
//                                             <p>
//                                                 Bij Tellow gebruiken we voor extra veiligheid géén wachtwoorden. In plaats daarvan sturen we je een link, waar je mee kunt inloggen.
//                                             </p>
//                                             <br />
//                                             <p>
//                                                 <strong>We gaan je nu zo'n e-mail sturen! &nbsp; ✉️</strong>
//                                             </p>
//                                             <p>
//                                                 <sup>(Check je voor de zekerheid je spam?)</sup>
//                                             </p>
//                                         </Explanation>
//                                     </Motion>
//                                 </>
//                             )}
//                             {/* We made an account for you! */}
//                             {done && created && (
//                                 <Motion>
//                                     <Success>
//                                         <Check />
//                                         <p>Tellow-account aangemaakt!</p>
//                                     </Success>
//                                 </Motion>
//                             )}
//                             {/* We sent you the password. */}
//                             {done && !created && mailSent && (
//                                 <Motion>
//                                     <Success>
//                                         <Check />
//                                         <p>Verzonden. Check je inbox!</p>
//                                     </Success>
//                                 </Motion>
//                             )}
//                         </Margin>
//                     </Card>

//                     <Conditions hidden={done || busy}>
//                         <p>
//                             Door Tellow te blijven gebruiken ga je akkoord met onze <a href="https://www.tellow.nl/voorwaarden/">voorwaarden</a> en{' '}
//                             <a href="https://www.tellow.nl/privacy/">privacyverklaring</a>.
//                         </p>
//                     </Conditions>
//                 </SignupContainer>
//             </AnimatePresence>
//         </SignupWrapper>
//     )
// }

// /**
//  * Main component which contains the
//  * promotional copy content, and the
//  * registration form on the side.
//  */
// export default function Register({ data }) {
//     /**
//      * Make sure the data is actually present.
//      */
//     const title = data && data.register_title_1 && data.register_title_1.text
//     const subtitle = data && data.register_title_2 && data.register_title_2.text
//     const body = data && data.register_body && data.register_body.text
//     const variant = data && data.background

//     /**
//      * If no title found;
//      * fall back to these
//      * 'general' texts.
//      */
//     const fallbacks = {
//         title: 'Boekhouden;',
//         subtitle: 'voortaan een eitje.',
//         body: 'Boekhouden moeilijk? Pffft! Dat hoeft helemáál niet zo te zijn! Met Tellow wordt hoekbouden een eitje. Jij upload de bon, wij doen de rest.',
//     }
//     return (
//         <Wrapper variant={variant || 'white'}>
//             <Main>
//                 <Container>
//                     <Grid>
//                         <Heading>
//                             <div>
//                                 <h1>
//                                     <span className="first">{title || fallbacks.title}</span>
//                                     <span className="second">{subtitle || fallbacks.subtitle}</span>
//                                 </h1>
//                                 <p>{body || fallbacks.body}</p>
//                             </div>
//                         </Heading>
//                         <Signup variant={variant} />
//                     </Grid>
//                 </Container>
//             </Main>
//         </Wrapper>
//     )
// }

const Register = () => <></>

export default Register
